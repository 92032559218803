import ReviewData from "../store/testimonals";
import InfoCards from "../components/InfoCards";
import Review from "../components/Review";
import AboutCard from "../components/AboutCard";
import {useOutletContext } from "react-router-dom";
import { useEffect } from "react";


function About(props) {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "js/main.js";
        document.body.appendChild(script);
        return()=>{
            document.getElementById("ftco-loader").classList = "show fullscreen";
        }
    });
    const SiteData = useOutletContext().SiteData;
    return (
        <>
            <section className="hero-wrap hero-wrap-2 js-fullheight" style={{ "backgroundImage": " url('images/bg_1.jpg')" }}>
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-center">
                        <div className="col-md-9 ftco-animate pb-5 text-center">
                            <p className="breadcrumbs"><span className="mr-2"><a href="/">Home <i className="fa fa-chevron-right"></i></a></span> <span>{SiteData.about.head} </span></p>
                            <h1 className="mb-0 bread">{SiteData.about.head}</h1>
                        </div>
                    </div>
                </div>
            </section>
            <br />
            <br />
            <br />
            <br />
            <AboutCard data={SiteData.about} />
            <InfoCards data={SiteData.infoCards} />
            <br />
            <br />
            <section className="ftco-intro ftco-section ftco-no-pt">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center">
                            <div className="img" style={{ "backgroundImage": "url(images/bg_2.jpg)" }}>
                                <div className="overlay"></div>
                                <h2>{SiteData.companyName}</h2>
                                <p>{SiteData.about.tag}</p>
                                <p className="mb-0"><a href="#" className="btn btn-primary px-4 py-3">Ask For A Quote</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ftco-section ftco-about img" style={{ "backgroundImage": "url(images/bg_4.jpg)" }}>
                <div className="overlay"></div>
                <div className="container py-md-5">
                    <div className="row py-md-5">
                        <div className="col-md d-flex align-items-center justify-content-center">
                            <a href="https://vimeo.com/45830194" className="icon-video popup-vimeo d-flex align-items-center justify-content-center mb-4">
                                <span className="fa fa-play"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About;