function AboutCard(props) {
    const about = props.data;
  return (
<section className="ftco-section ftco-about ftco-no-pt img" id="about">
        <div className="container">
          <div className="row d-flex">
            <div className="col-md-12 about-intro">
              <div className="row">
                <div className="col-md-6 d-flex align-items-stretch">
                  <div className="img d-flex w-100 align-items-center justify-content-center" style={{ "backgroundImage": "url(images/about-1.jpg)" }}>
                  </div>
                </div>
                <div className="col-md-6 pl-md-5 py-5">
                  <div className="row justify-content-start pb-3">
                    <div className="col-md-12 heading-section ftco-animate">
                      <span className="subheading">{about.head}</span>
                      <h2 className="mb-4">{about.tag}</h2>
                      {about.desc.map((val,key)=>(
                        <p key={key} >{val}</p>
                      ))}
                      {/* <p><a href="#" className="btn btn-primary">Book Your Destination</a></p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>)
}
export default AboutCard;