import ReviewData from "../store/testimonals";
import InfoCards from "../components/InfoCards";
import Review from "../components/Review";
import AboutCard from "../components/AboutCard";
import {useOutletContext } from "react-router-dom";
import { useEffect } from "react";

function Home(props) {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "js/main.js";
        document.body.appendChild(script);
        return()=>{
            document.getElementById("ftco-loader").classList = "show fullscreen";
        }
      });
    
    const SiteData= useOutletContext().SiteData;
    const banner = SiteData.banner;
    return (
        <>
            <div className="hero-wrap js-fullheight" style={{ "backgroundImage": "url('images/bg_5.jpg')" }}>
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text js-fullheight align-items-center" data-scrollax-parent="true">
                        <div className="col-md-7 ftco-animate">
                            <span className="subheading">{banner.head}</span>
                            <h1 className="mb-4">{banner.slogan}</h1>
                            <p className="caps">{banner.tag}</p>
                        </div>
                        <a href="https://vimeo.com/45830194" className="icon-video popup-vimeo d-flex align-items-center justify-content-center mb-4">
                            <span className="fa fa-play"></span>
                        </a>
                    </div>
                </div>
            </div>
            <InfoCards data={SiteData.infoCards} />
            <AboutCard data={SiteData.about} />
            <Review data={ReviewData} />
            <br />
            <br />
            <section className="ftco-intro ftco-section ftco-no-pt">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center">
                            <div className="img" style={{ "backgroundImage": "url(images/bg_2.jpg)" }}>
                                <div className="overlay"></div>
                                <h2>{SiteData.companyName}</h2>
                                <p>{SiteData.about.tag}</p>
                                <p className="mb-0"><a href="/contact" className="btn btn-primary px-4 py-3">Ask For A Quote</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ftco-section ftco-about img" style={{ "backgroundImage": "url(images/bg_4.jpg)" }}>
                <div className="overlay"></div>
                <div className="container py-md-5">
                    <div className="row py-md-5">
                        <div className="col-md d-flex align-items-center justify-content-center">
                            <a href="https://vimeo.com/45830194" className="icon-video popup-vimeo d-flex align-items-center justify-content-center mb-4">
                                <span className="fa fa-play"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home;