import { Link, NavLink } from "react-router-dom";

function NavBar(props) {
  return (
      <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
        <div className="container">
          <a className="navbar-brand" href="index.html">{props.data.brandBig}<span>{props.data.brandSmall}</span></a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="oi oi-menu"></span> Menu
          </button>

          <div className="collapse navbar-collapse" id="ftco-nav">
            <ul className="navbar-nav ml-auto">
            {props.data.Links.map((val,key)=>(
              <li key={key} className={`nav-item active`}><NavLink to={val.link} className="nav-link">{val.name}</NavLink></li>
            ))}              
            </ul>
          </div>
        </div>
      </nav>
  )
}

export default NavBar;
