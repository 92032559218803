let reviews = [{
    "desc":"Went on an 18days and three countries trip in the month of December organized by them. Indonesia(Bali), Vietnam (Ho chi minh), Thiland(Phuket, Krabi, Bangkok). It was on memorable trip as we could enjoy the entire 18days without any stress. The iterenary has been sent in prior and Sunil has been very flexible and responsive. We had a safe and comfortable stay in every city we traveled to. Must say, very affordable and worth the money we spent. Will definitely go back to them for all our future trips. Thanks Sunil.",
    "name":"Sush M",
    "profilePic":""
},
{
    "desc":"Trustable. I have came across two trips with this travels and their hospitality and arrangements are very good. Impressive.",
    "name":"Krishna k",
    "profilePic":""
},
{
    "desc":"Best tourism... reasonable price compare to other tourism...went for kashmir..all the plans are perfectly oraganised... didn't face any difficulties....my tour arrangement is made by Sunil..he is very gunuine.no doubts,you can choose getz tourism and save the memories",
    "name":"Customer",
    "profilePic":""
},
{
    "desc":"Had a great experience. Recently went a family trip organised by them. It was so good and we enjoyed a lot.",
    "name":"JB",
    "profilePic":""
}
]
export default reviews;