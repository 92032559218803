function Review(props) {

  return (
    <section className="ftco-section testimony-section bg-bottom" style={{ "backgroundImage": "url(images/bg_1.jpg)" }}>
      <div className="overlay"></div>
      <div className="container">
        <div className="row justify-content-center pb-4">
          <div className="col-md-7 text-center heading-section heading-section-white ftco-animate">
            <span className="subheading">Testimonial</span>
            <h2 className="mb-4">Tourist Feedback</h2>
          </div>
        </div>
        <div className="row ftco-animate">
          <div className="col-md-12">
            <div className="carousel-testimony owl-carousel">
              {props.data.map((val, key) => (
                <div key={key} className="item">
                  <div className="testimony-wrap py-4">
                    <div className="text">
                      <p className="star">
                        <span className="fa fa-star"></span>
                        <span className="fa fa-star"></span>
                        <span className="fa fa-star"></span>
                        <span className="fa fa-star"></span>
                        <span className="fa fa-star"></span>
                      </p>
                      <p className="mb-4">{val.desc}</p>
                      <div className="d-flex align-items-center">
                        <div className="user-img" style={{ "backgroundImage": "url(images/person_1.jpg)" }}></div>
                        <div className="pl-3">
                          <p className="name">{val.name}</p>
                          {/* <span className="position">{val.desc}</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Review;