import AboutCard from "../components/AboutCard";
import { useOutletContext } from "react-router-dom";
import { useEffect } from "react";


function Contact(props) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "js/main.js";
    document.body.appendChild(script);
    return()=>{
      document.getElementById("ftco-loader").classList = "show fullscreen";
  }
  });
  const SiteData = useOutletContext().SiteData;
  const banner = SiteData.banner;
  return (
    <>
      <section className="hero-wrap hero-wrap-2 js-fullheight" style={{"backgroundImage":" url('images/bg_1.jpg')"}}>
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-center">
            <div className="col-md-9 ftco-animate pb-5 text-center">
              <p className="breadcrumbs"><span className="mr-2"><a href="/">Home <i className="fa fa-chevron-right"></i></a></span> <span>Contact us</span></p>
              <h1 className="mb-0 bread">Contact us</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-section ftco-no-pb contact-section mb-4">
        <div className="container">
          <div className="row d-flex contact-info">
            <div className="col-md-4 d-flex">
              <div className="align-self-stretch box p-4 text-center">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="fa fa-map-marker"></span>
                </div>
                <h3 className="mb-2">Address</h3>
                <p>{SiteData.contact.address}</p>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="align-self-stretch box p-4 text-center">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="fa fa-phone"></span>
                </div>
                <h3 className="mb-2">Contact Number</h3>
                <p><a href="tel://1234567920">{SiteData.contact.phone}</a></p>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="align-self-stretch box p-4 text-center">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="fa fa-paper-plane"></span>
                </div>
                <h3 className="mb-2">Email Address</h3>
                <p><a href="mailto:info@yoursite.com">{SiteData.contact.email}</a></p>
              </div>
            </div>
            {/* <div className="col-md-3 d-flex">
              <div className="align-self-stretch box p-4 text-center">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="fa fa-globe"></span>
                </div>
                <h3 className="mb-2">Website</h3>
                <p><a href="#">yoursite.com</a></p>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section className="ftco-section contact-section ftco-no-pt">
        <div className="container">
          <div className="row block-9">
            <div className="col-md-6 order-md-last d-flex">
              <form action="sendemail.php" className="bg-light p-5 contact-form">
                <div className="form-group">
                  <input type="text" name="name" className="form-control" placeholder="Your Name" required/>
                </div>
                <div className="form-group">
                  <input type="text" name="email" className="form-control" placeholder="Your Email" required/>
                </div>
                <div className="form-group">
                  <input type="text" name="subject" className="form-control" placeholder="Subject" required/>
                </div>
                <div className="form-group">
                  <textarea name="message" id="" cols="30" rows="7" className="form-control" placeholder="Message" required></textarea>
                </div>
                <div className="form-group">
                  <input type="submit" value="Send Message" className="btn btn-primary py-3 px-5"/>
                </div>
              </form>

            </div>

            <div className="col-md-6 d-flex">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.1843486019175!2d77.01458521480299!3d11.024791392153304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba85785d04b2191%3A0xade4a08749cac523!2s74%2C%20VOC%20St%2C%20Periyar%20Nagar%2C%20DJ%20Nagar%2C%20Coimbatore%2C%20Tamil%20Nadu%20641004!5e0!3m2!1sen!2sin!4v1675007977484!5m2!1sen!2sin" width="600" height="450" style={{"border":"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </section>
      {/* <AboutCard data={SiteData.contact} /> */}
      
      <section className="ftco-section ftco-about img" style={{ "backgroundImage": "url(images/bg_4.jpg)" }}>
        <div className="overlay"></div>
        <div className="container py-md-5">
          <div className="row py-md-5">
            <div className="col-md d-flex align-items-center justify-content-center">
              <a href="https://vimeo.com/45830194" className="icon-video popup-vimeo d-flex align-items-center justify-content-center mb-4">
                <span className="fa fa-play"></span>
              </a>
            </div>
          </div>
        </div>
      </section>
      <br/><br/>
    </>
  )
}

export default Contact;