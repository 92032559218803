function InfoCard(props) {
  const data = props.data;
  return (
    <section className="ftco-section services-section">
      <div className="container">
        <div className="row d-flex">
          <div className="col-md-6 order-md-last heading-section pl-md-5 ftco-animate d-flex align-items-center">
            <div className="w-100">
              <span className="subheading">{data.head}</span>
              <h2 className="mb-4">{data.tag}</h2>
              {data.desc.map((val,key)=>(
                <p key={key}>{val}</p>
              ))}
              {/* <p><a href="#" className="btn btn-primary py-3 px-4">Search Destination</a></p> */}
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              {data.cards.map((val,key)=>(
                <div key={key} className="col-md-12 col-lg-6 d-flex align-self-stretch ftco-animate">
                  <div className="services services-1 color-1 d-block img" style={{ "backgroundImage": `url(images/services-${key+1}.jpg)` }}>
                    <div className="icon d-flex align-items-center justify-content-center"><span className={`${val.icon}`}></span></div>
                    <div className="media-body">
                      <h3 className="heading mb-3">{val.name}</h3>
                      <p>{val.desc}</p>
                    </div>
                  </div>
               </div>
              ))}
              
            </div>
          </div>
        </div>
      </div>
    </section>     
  )
}
export default InfoCard;
