//site data
let companyName = "Globe Trotter’Z Tourism";
export default {
    companyName:companyName,
    banner:{
        head:`Welcome to ${companyName}`,
        slogan:"KINDLE UP YOUR EXPLORER within",
        tag:"Explore the entire globe with us"
    },
    nav:{
        brandBig:companyName.replace("Tourism",""),
        brandSmall:companyName.replace("Globe Trotter’Z",""),
        Links:[
            {name:"Home",
            link:"/"
            },
            {name:"About",
            link:"/about"
            },
            // {name:"Destination",
            // link:"#destination"
            // },
            // {name:"Hotel",
            // link:"#hotel"
            // },
            // {name:"Blog",
            // link:"#blog"
            // },
            {name:"Contact",
            link:"contact"
            }
        ]
    },
    infoCards:{
        head:"With Us",
        tag:"YOUR DREAM TRAVEL IS JUST ONE CLICK AWAY!",
        desc:[
            "Dreaming about a budget friendly, fun, and adventurous trip with your friends and family?",
            "Finding it difficult to discover the right place at the right time?",
            "The Earth is alive with vibrant colors that beg to be discovered and shared with our loved ones. We'd love for you to ride with us down the steep, icy slopes of the green hills to the warm, happy beaches. Hurry! Get packing!"
        ],
        cards:[{
            name:"Activities",
            desc:"Experience an enthralling trip with us as, all outdoor and indoor activities like river rafting, kayaking, boating, parasailing, canoeing etc will be coordinated during the trips in prior, which are always optional.",
            icon:"flaticon-paragliding"
        },
        {
            name:"Travel Arrangements",
            desc:"To make your journey comfortable all transportation arrangements (road, rail, air, and cruise) will be made in a proper and systematic manner.",
            icon:"flaticon-route"
        },
        {
            name:"Private Guide",
            desc:"We plan each trip with much care as every group travel will be supervised and guided by a personal guide. Other excursions will be coordinated with a local tour manager at the designated locations.",
            icon:"flaticon-tour-guide"
        },
        {
            name:"Google Reviews",
            desc:"We are glad to inform you that, we are rated 4.8/5 in google reviews with happy and satisfied travellers who chose us",
            icon:"flaticon-map"
        }]
    },
    about:{
        head:"About Us",
        tag:"COME DISCOVER THE WORLD'S MARVELS WITH US!",
        desc:[
            `${companyName} is proud to offer customised tour packages that fit your budget, both for trips within the country and trips abroad. Officially registered tourism operators that serve as a one-stop shop for tour plans for school and college students, corporates, and even small groups. We guarantee hospitality, comfort and safety which are the pillars of our travel agency.`,
            "We promise to make your travels memorable. Stop waiting for the right time and start making memories!"
        ]
    },
    contact:{
        address:"74, VOC Street, BR Puram, Peelamedu, Coimbatore - 641004.",
        phone:"8754721217",
        email:"getztourism@gmail.com"

    }

}

