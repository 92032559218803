import SiteData from "../store/site";
import NavBar from "../components/NavBar";
import {Outlet} from "react-router-dom";

function App(props) {
  const banner = SiteData.banner;
  return (
    <>
      <NavBar data={SiteData.nav}/>
      <Outlet context={{SiteData:SiteData}} />
      <footer className="ftco-footer bg-bottom ftco-no-pt" style={{ "backgroundImage": "url(images/bg_3.jpg)" }}>
        <div className="container">
          <div className="row mb-5">
            <div className="col-md pt-5">
              <div className="ftco-footer-widget pt-md-5 mb-4">
                <h2 className="ftco-heading-2">About</h2>
                {SiteData.about.desc.map((val, key) => (
                  <p key={key} >{val}</p>
                ))}
                <ul className="ftco-footer-social list-unstyled float-md-left float-lft">
                  <li className="ftco-animate"><a href="#"><span className="fa fa-twitter"></span></a></li>
                  <li className="ftco-animate"><a href="#"><span className="fa fa-facebook"></span></a></li>
                  <li className="ftco-animate"><a href="#"><span className="fa fa-instagram"></span></a></li>
                </ul>
              </div>
            </div>
            {/* <div className="col-md pt-5 border-left">
              <div className="ftco-footer-widget pt-md-5 mb-4 ml-md-5">
                <h2 className="ftco-heading-2">Infromation</h2>
                <ul className="list-unstyled">
                  <li><a href="#" className="py-2 d-block">Online Enquiry</a></li>
                  <li><a href="#" className="py-2 d-block">General Enquiries</a></li>
                  <li><a href="#" className="py-2 d-block">Booking Conditions</a></li>
                  <li><a href="#" className="py-2 d-block">Privacy and Policy</a></li>
                  <li><a href="#" className="py-2 d-block">Refund Policy</a></li>
                  <li><a href="#" className="py-2 d-block">Call Us</a></li>
                </ul>
              </div>
            </div> */}
            {/* <div className="col-md pt-5 border-left">
              <div className="ftco-footer-widget pt-md-5 mb-4">
                <h2 className="ftco-heading-2">Experience</h2>
                <ul className="list-unstyled">
                  <li><a href="#" className="py-2 d-block">Adventure</a></li>
                  <li><a href="#" className="py-2 d-block">Hotel and Restaurant</a></li>
                  <li><a href="#" className="py-2 d-block">Beach</a></li>
                  <li><a href="#" className="py-2 d-block">Nature</a></li>
                  <li><a href="#" className="py-2 d-block">Camping</a></li>
                  <li><a href="#" className="py-2 d-block">Party</a></li>
                </ul>
              </div>
            </div> */}
            <div className="col-md pt-5 border-left">
              <div className="ftco-footer-widget pt-md-5 mb-4">
                <h2 className="ftco-heading-2">Have a Questions?</h2>
                <div className="block-23 mb-3">
                  <ul>
                    <li><span className="icon fa fa-map-marker"></span><span className="text">{SiteData.contact.address}</span></li>
                    <li><a href="#"><span className="icon fa fa-phone"></span><span className="text">{SiteData.contact.phone}</span></a></li>
                    <li><a href="#"><span className="icon fa fa-paper-plane"></span><span className="text">{SiteData.contact.email}</span></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-md-12 text-center">
						
							Copyright &copyC<script>document.write(new Date().getFullYear())</script> All rights reserved | This template is made with <i className="fa fa-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib</a>							
						</div> */}
          </div>
        </div>
      </footer>
      <a data-container="body" data-toggle="popover" data-placement="top" data-content="Click here to chat with us" className="wa-click-msg" id="wa-click-msg" href="https://wa.me/8754721217?text=I%27m+interested+in+getting+a+quota+on+the+destination" target="_blank"><img src="images/wa.png"  width="75" height="75"/></a>
      <div id="ftco-loader" className="show fullscreen"><svg className="circular" width="48px" height="48px"><circle className="path-bg" cx="24" cy="24" r="22" fill="none" strokeWidth="4" stroke="#eeeeee" /><circle className="path" cx="24" cy="24" r="22" fill="none" strokeWidth="4" strokeMiterlimit="10" stroke="#F96D00" /></svg></div>
    </>
  )
}

export default App;
